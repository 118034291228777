import axios from 'axios';
import { useEffect, useState } from 'react';

// api breakpoint
const baseUrl = `${process.env.REACT_APP_API_BASE_URL}api/monitoring`;
export function Monitoring() {
  const [status, setStatus] = useState<string>('Pending');

  const getStatus = () => {
    axios
      .get(`${baseUrl}`)
      .then(response => {
        setStatus(response.data);
      })
      .catch(error => console.error(`Error: ${error}`));
  };

  useEffect(() => {
    getStatus();
  }, []);

  return <>{status}</>;
}
